<template>
  <PatologiesPage
    title="GIST"
    subtitle1="I GIST (dall’inglese GastroIntestinal Stromal Tumors) sono tumori rari che possono originare lungo tutto il tratto gastrointestinale, dall’esofago fino al retto. In Italia si contano circa 800 nuovi casi ogni anno e colpiscono individui di età compresa principalmente tra i 55 ed i 65 anni. I GIST hanno la particolarità di svilupparsi dalle cellule mesenchimali che coordinano le contrazioni automatiche delle pareti del tubo digerente e che ne fanno progredire il contenuto.

L’organo più colpito è lo stomaco (60%) mentre sono meno comuni altre sedi come l’intestino tenue (25%), il colon e il retto (5%) e l’esofago (1%). È importante segnalare che non tutti i GIST sono da considerarsi maligni, esistono infatti anche forme benigne che non hanno pertanto la capacità di diffondersi in altre aree dell’organismo.

Nella maggior parte dei casi si tratta di tumori sporadici, cioè senza alcuna trasmissione ereditaria all’interno delle famiglie, ma esistono alcune sindromi genetiche che possono aumentare il rischio di sviluppare un GIST.

Dal punto di vista dei sintomi questi possono essere, nelle fasi iniziali, completamente assenti. La diagnosi avviene pertanto in modo del tutto casuale nel corso di esami eseguiti per altre ragioni. Nelle fasi avanzate è invece possibile che si manifestino alcuni sintomi tipici: il sanguinamento (sangue che fuoriesce dalla bocca con il vomito o dall’ano con le feci) dando origine a stati anemici anche importanti che portano a spossatezza e vaga stanchezza. Altri sintomi caratteristici variano in base alla sede di origine: il dolore addominale, la sensazione di ingombro per la presenza di una massa addominale, nausea e vomito associata a senso di sazietà precoce, inappetenza e perdita di peso.

"
    subtitle2="Per la diagnosi di GIST sono necessari esami di diagnostica per immagini non invasivi come la TAC, la  risonanza magnetica, la PET ed altri invece un po’ più invasivi come l’endoscopia e/o l’ecoendoscopia per effettuare una biopsia che permette la diagnosi di certezza. Gli esami strumentali consentono una corretta stadiazione della malattia che, così come avviene per tutti gli altri tumori, è fondamentale per la definizione della strategia terapeutica più adeguata."
    subtitle3="La scelta del trattamento più adatto in caso di GIST dipende dalla dimensione del tumore, dalla sede di origine e dalle condizioni generali del paziente. La tradizionale chemioterapia non è efficace in questi tumori. Nella maggior parte dei casi il trattamento iniziale è chirurgico, in questo modo è possibile asportare e curare definitivamente la malattia localizzata. In caso di malattia diffusa e non asportabile chirurgicamente è disponibile un farmaco mirato molto efficace: il suo nome è imatinib ed è costituito da una molecola che ha come bersaglio le proteine peculiari dei GIST. Il ruolo dell’imatinib è quello di bloccare la crescita del tumore. Dal punto di vista prognostico è considerato un farmaco molto efficace riuscendo ad ottenere una risposta in una elevata percentuale di pazienti. È una terapia che ha meno effetti collaterali della chemioterapia tradizionale ed è assunta per via orale, per questo motivo è normalmente ben accettato e tollerato dalla maggior parte dei pazienti. Nei casi più complessi è possibile inserire questo trattamento in un ambito multimodale e quindi utilizzato come trattamento adiuvante (cioè dopo l’asportazione chirurgica della massa principale) in quei pazienti selezionati considerati ad alto rischio di recidiva o come terapia neoadiuvante (prima dell’intervento), per ridurre la massa del tumore e facilitare così il lavoro del chirurgo che potrà anche considerare resezioni più conservative."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica.

"
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Gist",
  components: {
    PatologiesPage,
  },
};
</script>
